//export const BACKEND_URL = "http://localhost:5207";
//export const BACKEND_URL = "http://188.245.34.252:9000";
export const BACKEND_URL = "https://bridgeapi.wood-in-vision.com";

export const frontend_version = "0.9.0";

export const scales = {
  'fit': 'Füllend',
  'custom': 'Benutzerdefiniert',
  ' 500': '1:500',
  ' 1000': '1:1.000',
  ' 2500': '1:2.500',
  ' 5000': '1:5.000',
  ' 10000': '1:10.000',
  ' 15000': '1:15.000',
  ' 20000': '1:20.000',
  ' 25000': '1:25.000',
  ' 50000': '1:50.000',
  ' 75000': '1:75.000',
  ' 100000': '1:100.000',
  ' 250000': '1:250.000',
  ' 500000': '1:500.000',
  ' 1000000': '1:1.000.000'
};

export const paperSize = {
  //'A0': [841,1189],
  //'A1': [594,841],
  //'A2': [420,594],
  'A3': [297,420],
  'A4': [210,297],
  'A5': [148,210]
};

export const muiTypes = {
  "string": "string",
  "int": "number",
  "float": "number",
  "list": "singleSelect",
  "bool": "boolean",
  "date": "date",
  "datetime": "dateTime"
}

export const colors = [
  "#ffffff",
  "#7cfc00",
  "#ff4500",
  "#ffd700",
  "#436eee",
  "#ff7f00",
  "#cd00cd",
  "#a6a6a6",
  "#98fb98",
  "#fa8072",
  "#f0e68c",
];

export const suffixes = {
  "application/vnd.google-earth.kml+xml": "kml",
  "application/xml": "xml",
  "text/xml": "xml",
  "image/png": "png",
  "image/jpeg": "jpeg",
  "image/jpeg": "jpg",
  "string": "gpx",
  "string": "kmz",
  "application/pdf": "pdf",
  "application/msword": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/json": "json",
  "image/gif": "gif",
  "audio/mpeg": "mp3",
  "audio/wav": "wav",
};
import { BACKEND_URL, suffixes } from "./defines";

export function createConfigFromXML(xml) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const rootElement = xmlDoc.documentElement;
  let config = {
    rows: []
  }

  for(let child of rootElement.children) {
    var row = {}
    var attributes = child.textContent.split(",");

    for(let attrib of attributes) {
      var splitter = attrib.indexOf("=");
      var key = attrib.slice(0, splitter);
      var value = attrib.slice(splitter+1, undefined);
      value = value.replaceAll("&comma;", ",");

      if(key == "list") {
        let list = [];
        let listItems = value.split(";");
        for(let listItem of listItems) {
          let item = {
            key: listItem.split(":", 2)[0],
            value: listItem.split(":", 2)[1]
          }
          list.push(item);
        }

        if(child.attributes["k"].value.includes("addInputControl")) {
          row[key] = list;
        } else {
          config[key] = list;
        }

      } else if(key.includes('ui.')) {
        let attribList = [];
        let attribs = value.split(";");

        for(let attrib of attribs) {
          var splitter = attrib.indexOf(":");
          var innerKey = attrib.slice(0, splitter);
          var innerValue = attrib.slice(splitter+1, undefined);

          let item = {
            key: innerKey,
            value: innerValue
          }
          attribList.push(item);
        }

        if(child.attributes["k"].value.includes("addInputControl")) {
          row[key] = attribList;
        } else {
          config[key] = attribList;
        }

      } else {
        if(child.attributes["k"].value.includes("addInputControl")) {
          row[key] = value;
        } else {
          config[key] = value;
        }
      }
    }

    if(child.attributes["k"].value.includes("addInputControl")) {
      config["rows"].push(row)
    }

  }
  return config;
}

export function fillTemplate(config, template, values) {
  const filledTemplate = template.replace(/\$\{([^\s}]+)\}/g, (match, key) => {
    key = key.replaceAll("=", "");
    let configRow = config.rows.find((row) => row.id == key);
    if(configRow != null && configRow.type == "list") {
      if(configRow.list != null) {
        let listItem = configRow.list.find((listItem) => listItem.key == values.poi[key]);
        if(listItem != null) {
          return listItem.value || "";
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return values.poi[key] || "";
    }
  });
  return filledTemplate;
}

export function getInterpretetTitle(poi, config) {
  let textTemplate = config.config["ui.android"].find((element) => element.key == "instanceMenuLabelTemplate")
  if(textTemplate != null && poi != null) {
    let text = fillTemplate(config.config, textTemplate.value, poi);
    return <div dangerouslySetInnerHTML={{ __html: text }}/> ;
  } else  {
    return <div></div>;
  }
}

export function getSimpleLabel(label) {
  let parts = label.split("|");
  if(parts.length > 1) {
    return parts[parts.length-1];
  } else {
    return label;
  }
}

export function formatText(text) {
  if(text.includes("<b>")) {
    return <b>{text.replaceAll("<b>", "").replaceAll("</b>", "").replaceAll("<b/>", "")}</b>
  } else {
    return text;
  }
}

export function isSameCompany(username, loginName) {
  let poiCreationLoginName = loginName.split('-')[0];
  return username.includes(poiCreationLoginName);
}

export function getMainUserName(loginName) {
  let prefix = loginName.split("-")[0];
  let postfix = loginName.split("-")[1];
  return prefix + "-poicfg";
}

export async function callApiAsync(url, requestOptions, callback) {
  fetch(url, requestOptions)
  .then(resp => {
    if(resp.status == 200) {
      return resp.json();
    }
    return Promise.reject(resp);
  })
  .then(json => callback(json))
  .catch(error => {
    callback(error);
  })
}

export async function callApiTextAsync(url, requestOptions, callback) {
  fetch(url, requestOptions)
  .then(resp => resp.text())
  .then(json => callback(json))
  .catch(error => {
    //console.log(error);
    callback(error);
  })
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export function decToHex(decimal) {
  // Convert the decimal to a hexadecimal string with base 16
  const hexString = decimal.toString(16);
  // Return the uppercase hexadecimal string
  return hexString.toUpperCase();
}

export function rgbToHexA(rgb) {
  // Check for invalid input length or values
  if (rgb.length !== 4 || rgb.some(val => val < 0 || val > 255)) {
    throw new Error('Invalid RGB array. Values must be between 0 and 255.');
  }

  const [r, g, b, a] = rgb;
  const hex = [r, g, b].map(val => val.toString(16).padStart(2, '0')).join('');
  return `#${hex}${a !== 1 ? (a * 255).toString(16).padStart(2, '0') : ''}`;
}

export function handleFileDownload(file, username, password, e) {
  if(file.base64) {
    console.log("has base64")
  } else {
    let auth = btoa(username + ":" + password);
    let formData = new FormData();
    formData.append('url', "https://portal.wood-in-vision.com/api/v1/blob/" + file.guid);
    formData.append("systemAuthToken", auth)
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    
    const requestOptions = {
      method: "POST"
    };

    fetch(url, {
      body: formData,
      ...requestOptions,
    })
    .then((response) => response.blob())
    .then(blob => {
      const href = window.URL.createObjectURL(blob);
      let fileName = file.l;
      if(!checkForSuffix(fileName)) {
        let suffix = suffixes[file.mimeType];
        fileName = fileName + "." + suffix;
      }
      downloadFile(href, fileName);
      e.stopPropagation();
    })
    .catch((error) => console.error(error));
  }
}


async function downloadFile(href, name) {
  const a = document.createElement("a");
  a.download = name;
  a.href = href;
  a.click();
  a.href = "";
}

export function openUrl(url, e) {
  window.open(url, "_blank");
  e.stopPropagation();
}

function checkForSuffix(str){
  var suffix = str.slice((Math.max(0, str.lastIndexOf(".")) || Infinity) + 1);
  console.log(suffix);
  if(Object.entries(suffixes).map((entry) => entry[1]).includes(suffix)) {
    return suffix;
  }
  return null;
}
import React from 'react'
import SelectedPoiListProvider from './SelectedPoiProvider';
import ExportedImageProvider from './MapProvider';

const Providers = ({children}) => {
  return (
    <>
        <SelectedPoiListProvider>
        <ExportedImageProvider>
         {children}
        </ExportedImageProvider>
        </SelectedPoiListProvider>
    </>
  )
}

export default Providers
import "./views/mainView/MainView";
import "./widgets/navBar/NavBarItem.css";
import LoginView from "./views/loginView/LoginView";
import MainView from "./views/mainView/MainView";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { deDE } from '@mui/x-data-grid/locales';
import { callApiAsync } from "./js/netwake_config_reader";
import { BACKEND_URL } from "./js/defines";
import Providers from "./context";
import DataGridTest, { AgGrid } from "./views/dataGridtest";
import { LicenseInfo } from '@mui/x-license';

function App() {
  const [username, setUserName] = useState(""); //kwf-poicfg wiv-kjb kwf-901-admin001
  const [password, setPassword] = useState(""); //Wk8cqxKa J5588JPr jkcmEUe5
  const [bearerToken, setBearerToken] = useState(""); //Wk8cqxKa J5588JPr jkcmEUe5

  const [loading, setLoading] = useState(false);

  const [usernameErr, setUserNameErr] = useState(false); //kwf-poicfg wiv-kjb kwf-901-admin001
  const [passwordErr, setPasswordErr] = useState(false); //Wk8cqxKa J5588JPr jkcmEUe5

  const [themeType, setThemeType] = useState("light");
  const theme = createTheme({
    palette: {
      mode: themeType == "dark" ? "dark" : "light",
      primary: {
        main: '#009900',
      },
      background: {
        default: themeType == "dark" ? "#333" : "#eee",
        paper: themeType == "dark" ? "#232323" : "#ddd"
      }
    },
  }, deDE);

  function onThemeChanged(themeType) {
    setThemeType(themeType);
  }

  function onUserName() {
    setUserNameErr(false);
    setPasswordErr(false);
  }

  function onPassword() {
    setUserNameErr(false);
    setPasswordErr(false);
  }

  async function onLogin(username, password) {
    const header = new Headers();
    header.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "userName": username,
      "password": password
    });

    const requestOptions = {
      method: "POST",
      headers: header,
      body: raw,
      redirect: "follow"
    };

    let url = BACKEND_URL + "/api/Auth/Login";
    setLoading(true);
    callApiAsync(url, requestOptions, (resp) => {
      if(resp.status != 400) {
        if(resp.token != null) {
          setUserName(username);
          setPassword(password);
          setBearerToken(resp.token);
        }
      } else {
        setUserNameErr(true);
        setPasswordErr(true);
      }
      setLoading(false);
    })
  }

  function onLogout() {
    setUserName("");
    setPassword("");
    setBearerToken("");
  }

  LicenseInfo.setLicenseKey('3d8e4376b989312ca4a07d558a7b65d3Tz05NTg3MSxFPTE3NTQ4MDg3NDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

  return bearerToken != "" || username != "" ? (
    <Providers>
      <ThemeProvider theme={theme}>
        <MainView onLogout={onLogout} username={username} password={password} bearerToken={bearerToken}/>
      </ThemeProvider>
    </Providers>
  ) : (
    <Providers>
      <ThemeProvider theme={theme}>
        {/*<DataGridTest></DataGridTest>
        <AgGrid></AgGrid>*/}
        <LoginView onLogin={onLogin} usernameErr={usernameErr} passwordErr={passwordErr} loading={loading} onUserName={onUserName} onPassword={onPassword}/>
      </ThemeProvider>
    </Providers>
  );
}

export default App;
import React, { createContext, useContext, useReducer } from 'react';



const SelectedPoiContext = createContext();
const initialValues = {
  data:null,
  configs:null, 
}
export const SelectedPoiActions =  {
  setSelectedPoi: 'setSelectedPoi',
  setSelectedConfigs: 'setSelectedConfigs'
};
export const SelectedPoiReducer = (state, action) => {
  switch (action.type) {
    
    case SelectedPoiActions.setSelectedPoi:
      return {
        ...state, 
        data: action.payload,
      };
    case SelectedPoiActions.setSelectedConfigs:
      return {
        ...state, 
        configs: action.payload,
      };
    case SelectedPoiActions.setOutputName:
      return {
        ...state, 
        outputName: action.payload,
      };
 
    default:
      return state;
  }
};


const SelectedPoiListProvider = ({children}) => {
  const [state, dispatch] = useReducer(SelectedPoiReducer, initialValues);
  
  return (
    <SelectedPoiContext.Provider value={{state, dispatch}}>
      {children}
    </SelectedPoiContext.Provider>
  );
};

export default SelectedPoiListProvider;
export const useSelectedPoiList = () => useContext(SelectedPoiContext)
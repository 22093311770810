// react
import React, { useState, useEffect, useRef } from "react";

// openlayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import OSM from "ol/source/OSM";
import KML from "ol/format/KML.js";
import { fromLonLat, toLonLat } from "ol/proj.js";
import { Control, ScaleLine, defaults as defaultControls } from "ol/control.js";
import "./BasicMapExport.css";
import { Feature } from "ol";
import { Point } from "ol/geom";
import Icon from "ol/style/Icon";
import Text from "ol/style/Text";
import { Fill, Stroke, Style } from 'ol/style.js';
import TileWMS from "ol/source/TileWMS.js";
import circleIcon from "../../assets/circle.svg";
import northPointer from "../../assets/north_pointer.svg";
import poweredByWiv from "../../assets/WIV_poweredby_Logo.png";
import CircleStyle from "ol/style/Circle";
import { useMap } from "../../context/MapProvider";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import { scales } from "../../js/defines";
import { setMapScale } from 'ol-ext/geom/sphere';
import * as htmlToImage from 'html-to-image';
import XYZ from "ol/source/XYZ";
import { createEmpty, extend } from "ol/extent";

function BasicMapExport(props) {
  // set intial state
  const mapElement = useRef();
  const mapRef = useRef();
  const baseLayerRef = useRef();

  const posLayerRef = useRef();
  const vecLayerRef = useRef();

  const oemLabelRef = useRef();
  const northArrowRef = useRef();
  const mapInfoRef = useRef();
  const scaleLineRef = useRef();

  const [currentMapPos, setCurrentMapPos] = useState([]);




  var gisStyleCaches = {};

  const [map, setMap] = useState();

  const [baseSrc, setBaseSrc] = useState(
    new XYZ({
      url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      crossOrigin:"anonymous",
    })
    /*new OSM({
      attributions: "© WOOD.IN.VISION - © OpenStreetMap contributions."
    })*/
  );

  function evaluateMarkerStyle(feature, resolution) {
    //Cluster
    let features = feature.get("features");
    if (features) {
      let text;
      let textTemplate;
      let iconSrc = circleIcon;
      let poiUniqueId = "";
      if (features.length > 0) {
        iconSrc = features[0].get("markerIcon");
        poiUniqueId = features[0].get("layerId");
        feature = features[0];
        textTemplate = features[0].get("markerTextTemplate");
        if (props.mapConfig[poiUniqueId] ? props.mapConfig[poiUniqueId].showIconsText : false) {
          text = replaceTemplate(features[0], textTemplate, false);
        }
      }

      let gisStyle = props.selectedStyle
        ? props.gisStyles[poiUniqueId]
          ? props.gisStyles[poiUniqueId].find(
              (element) =>
                element.poi.std.guid ==
                props.selectedStyle[poiUniqueId.replace("_x_map", "")]
            )
          : null
        : null;
      if (gisStyle == null) {
        return new Style({
          image: new Icon({
            anchor: [0.5, 1],
            src: iconSrc,
          }),
          text: new Text({
            font: "16px Calibri,sans-serif",
            fill: new Fill({
              color: "#000",
            }),
            stroke: new Stroke({
              color: "#fff",
              width: 3,
            }),
            offsetY: -10,
            textBaseline: "top",
            declutterMode: "declutter",
            text: features.length > 1 ? features.length.toString() : text,
          }),
        });
      }

      let children = gisStyle.childs.sort(function (a, b) {
        return a.poi.sequenceNumber - b.poi.sequenceNumber;
      });

      let fillColor = "#ff000000";
      let fillColorSet = false;
      let strokeColor = "#ff0000";
      let strokeColorSet = false;
      let strokeWidth = 1;
      let strokeWidthSet = false;
      let circleD = 0;
      let circleDSet = false;

      let fontColor = "#000000";
      let fontColorSet = false;
      let fontBgColor = "#ffffff";
      let fontBgColorSet = false;

      let textSet = false;
      let textSize;
      let textSizeSet = false;

      let iconSrcSet = false;

      for (let rule of children) {
        if (rule.poi.key == "FILL_COLOR") {
          if (
            !fillColorSet &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            fillColor = rule.poi.value;
            fillColorSet = true;
          }
        }

        if (rule.poi.key == "LINE_COLOR") {
          if (
            !strokeColorSet &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            strokeColor = rule.poi.value;
            strokeColorSet = true;
          }
        }

        if (rule.poi.key == "LINE_WIDTH") {
          if (
            !strokeWidthSet &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            strokeWidth = rule.poi.value;
            strokeWidthSet = true;
          }
        }

        if (rule.poi.key == "CIRCLE_D") {
          if (!circleDSet && evaluateExpression(feature, rule.poi.expression)) {
            circleD = rule.poi.value;
            circleDSet = true;
          }
        }

        if (rule.poi.key == "FONT_COLOR") {
          if (
            !fontColorSet &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            fontColor = rule.poi.value;
            fontColorSet = true;
          }
        }

        if (rule.poi.key == "FONT_BG_COLOR") {
          if (
            !fontBgColorSet &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            fontBgColor = rule.poi.value;
            fontBgColorSet = true;
          }
        }

        if (rule.poi.key == "FONT_SIZE") {
          if (
            textSizeSet == null &&
            evaluateExpression(feature, rule.poi.expression)
          ) {
            textSize = rule.poi.value;
            textSizeSet = true;
          }
        }

        if (rule.poi.key == "LABEL") {
          if (!textSet && evaluateExpression(feature, rule.poi.expression)) {
            text = replaceTemplate(feature, rule.poi.value, false);
            textSet = true;
          }
        }

        if (rule.poi.key == "ICON_URL") {
          if (!iconSrcSet && evaluateExpression(feature, rule.poi.expression)) {
            iconSrc = replaceTemplate(feature, rule.poi.value != null && rule.poi.value != "" && rule.poi.value.includes("://") ? rule.poi.value : "http://www.portal.wood-in-vision.com/runtime/" + rule.poi.value, false);
            iconSrcSet = true;
          }
        }
      }

      return new Style({
        image:
          circleD > 0
            ? new CircleStyle({
                radius: pxTomm(circleD),
                fill: new Fill({
                  color: fillColor,
                }),
                stroke: new Stroke({
                  color: strokeColor,
                  width: strokeWidth,
                }),
              })
            : new Icon({
                src: iconSrc,
                crossOrigin: 'anonymous',
                anchor: iconSrcSet ? [0.5, 0.5] : [0.5, 1],
                width: iconSrcSet ? 24 : 24,
                height: iconSrcSet ? 24 : 24
              }),
        text: new Text({
          font: "13px Calibri,sans-serif",
          fill: new Fill({
            color: fontBgColor,
          }),
          stroke: new Stroke({
            color: fontColor,
            width: 3,
          }),
          offsetY: 5 + pxTomm(circleD),
          textBaseline: "top",
          declutterMode: "declutter",
          text: features.length > 1 ? features.length.toString() : text,
        }),
      });
    }
  }

  function drawPoisPosition() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    for (let e of Object.entries(props.selectedPois)) {
      let poiUniqueId = e[0];
      let pois = e[1];

      let poiCfg = null;
      for (let config of props.configs) {
        if (config.config.namespace + "/" + config.config.containerId == poiUniqueId) {
          poiCfg = config;
        } else {
          if (!config.childs) continue;
          for (let childConfig of config.childs) {
            if (childConfig.config.namespace + "/" + childConfig.config.containerId == poiUniqueId) {
              poiCfg = childConfig;
            } else {
              if (!childConfig.childs) continue;
              for (let childChildConfig of childConfig.childs) {
                if (childChildConfig.config.namespace + "/" + childChildConfig.config.containerId == poiUniqueId) {
                  poiCfg = childChildConfig;
                }
              }
            }
          }
        }
      }

      let posLayer;
      for (let layer of layers) {
        if (layer.get("layerId") == poiUniqueId) {
          posLayer = layer;
          posLayerRef.current = layer;
          continue;
        }
      }

      if (posLayer == null) {
        const vectorSource = new VectorSource();
        const clusterSource = new Cluster({
          distance: props.clusterIcons ? 30 : 1,
          minDistance: props.clusterIcons ? 20 : 1,
          source: vectorSource
        });
        posLayer = new VectorLayer({
          source: clusterSource,
          opacity: 1,
          layerId: poiUniqueId,
          zIndex: 999
        });
        posLayerRef.current = posLayer;
        if (map != undefined) {
          mapRef.current.addLayer(posLayer);
        }
      }

      posLayerRef.current.getSource().setDistance(props.clusterIcons ? 30 : 1);
      posLayerRef.current.getSource().setMinDistance(props.clusterIcons ? 20 : 1);

      let features = [];
      for (let poi of pois) {
        if (props.mapConfig[poiUniqueId] && props.mapConfig[poiUniqueId].showIcons) {
          let text = "";
          let uiDetail = poiCfg.config["ui.android"].find((item) => item.key == "instanceMenuLabelTemplate");
          if (uiDetail != null) {
            text = uiDetail.value;
          }
          let feature = new Feature({
            geometry: new Point([poi ? poi.std.loc.lon : 0, poi ? poi.std.loc.lat : 0]).transform(
              "EPSG:4326",
              "EPSG:3857",
            ),
            layerId: poiUniqueId,
            markerIcon: poiCfg ? "data:image/jpeg;base64," + poiCfg.config["containerChooseIconBase64"] : null,
            markerTextTemplate: poiCfg ? text : null,
            guid: poi.std.guid
          });
          feature.setProperties(poi.poi);
          features.push(feature);
        }
      }

      if (posLayer != undefined) {
        posLayerRef.current.setStyle(evaluateMarkerStyle);
      }

      if (posLayerRef.current) {
        posLayerRef.current.getSource().getSource().clear();
        posLayerRef.current.getSource().getSource().addFeatures(features);
      }
    }
  }

  //SELECTED VECTOR LAYER RENDER
  function calcScreenDPI() {
    const el = document.createElement('div');
    el.style = 'width: 1in;';
    document.body.appendChild(el);
    const dpi = el.offsetWidth;
    document.body.removeChild(el);
    return dpi;
  }

  function pxTomm(px) {
    let dpi = calcScreenDPI();
    let resultPx = (dpi * px) / 25.4;
    return Math.floor(resultPx);
  };

  function replaceTemplate(feature, stringToPopulate, isExpression) {
    const placeholderRegex = /\${([^}]+)}/g;
    let match;
    let newString = stringToPopulate;
    while ((match = placeholderRegex.exec(stringToPopulate)) !== null) {
      let variable = match[1];
      let defaultValue = "";
      let value;

      //Default Value
      if (variable.includes("=")) {
        let obj = variable.split("=");
        if (obj.length > 1) {
          defaultValue = obj[1];
        }
        value = feature.get(obj[0]);
      } else if (variable.includes(".key")) {
        let obj = variable.split(".key");
        let varName = obj[0];
        value = feature.get(varName);
      } else {
        value = feature.get(variable);
      }

      if (value != null) {
        if (typeof (value) == "string") {
          newString = newString.replace(match[0], '"' + value + '"');
        } else {
          newString = newString.replace(match[0], value);
        }
      } else {
        newString = newString.replace(match[0], '"' + defaultValue + '"');
      }
    }

    newString = newString.replaceAll("<br>", "\n").replaceAll("<br/>", "\n").replaceAll("<br />", "\n").replaceAll("<b>", "").replaceAll("<b/>", "").replaceAll("</b>", "");
    if (!isExpression) {
      newString = newString.replaceAll("\"", "");
    }

    //console.log(newString)

    return newString;
  }

  function evaluateExpression(feature, stringToPopulate) {
    let newString = replaceTemplate(feature, stringToPopulate, true);
    let boolean = false;
    if (newString.includes("{")) {
      boolean = false;
    } else {
      boolean = eval(newString);
    }
    return boolean;
  }

  function evaluateStyle(feature, resolution) {
    let poiUniqueId = feature.get("layerId");

    //USE CACHED STYLE
    let gisStyleCache = gisStyleCaches[poiUniqueId];
    let style;
    if (gisStyleCache != null) {
      style = gisStyleCache[feature.get("blobGuid")];
      if (style != null) {
        return new Style({
          text: new Text({
            text: style.text,
            font: style.textSize + "px Calibri,sans-serif",
            fill: new Fill({
              color: style.textColor,
            }),
            stroke: new Stroke({
              color: style.textBgColor,
              width: 3,
            }),
          }),
          stroke: new Stroke({
            color: style.strokeColor,
            width: style.strokeWidth,
          }),
          fill: new Fill({
            color: style.fillColor,
          }),
        });
      }
    }

    //RENDER STYLE
    let gisStyle = props.selectedStyle
      ? props.gisStyles[poiUniqueId.replace("_x_map", "")]
        ? props.gisStyles[poiUniqueId.replace("_x_map", "")].find(
            (element) =>
              element.poi.std.guid ==
              props.selectedStyle[poiUniqueId.replace("_x_map", "") + "//x_map"]
          )
        : null
      : null;
    if (gisStyle == null)
      return new Style({
        text: new Text({
          text: text,
          font: textSize + "px Calibri,sans-serif",
        }),
        stroke: new Stroke({
          color: "#ffffff",
          width: 2,
        }),
        fill: new Fill({
          color: "#ffffff44",
        }),
      });

    let children = gisStyle.childs.sort(function (a, b) {
      return a.poi.sequenceNumber - b.poi.sequenceNumber;
    });

    let fillColor = "#ff000000";
    let fillColorSet = false;
    let strokeColor = "#ff0000";
    let strokeColorSet = false;
    let strokeWidth = 1;
    let strokeWidthSet = false;
    let text;
    let textSize = 16;
    let textColor = "#000000";
    let textColorSet = false;
    let textBgColor = "#ffffffff";
    let textBgColorSet = false;

    for (let rule of children) {
      if (rule.poi.key == "FILL_COLOR") {
        if (!fillColorSet && evaluateExpression(feature, rule.poi.expression)) {
          fillColor = rule.poi.value;
          fillColorSet = true;
        }
      }

      if (rule.poi.key == "LINE_COLOR") {
        if (
          !strokeColorSet &&
          evaluateExpression(feature, rule.poi.expression)
        ) {
          strokeColor = rule.poi.value;
          strokeColorSet = true;
        }
      }

      if (rule.poi.key == "LINE_WIDTH") {
        if (
          !strokeWidthSet &&
          evaluateExpression(feature, rule.poi.expression)
        ) {
          strokeWidth = rule.poi.value;
          strokeWidthSet = true;
        }
      }

      if (rule.poi.key == "FONT_SIZE") {
        if (textSize == null && evaluateExpression(feature, rule.poi.expression)) {
          textSize = rule.poi.value;
        }
      }

      if (rule.poi.key == "FONT_COLOR") {
        if (!textColorSet && evaluateExpression(feature, rule.poi.expression)) {
          textColor = rule.poi.value;
          textColorSet = true;
        }
      }

      if (rule.poi.key == "FONT_BG_COLOR") {
        if (!textBgColorSet && evaluateExpression(feature, rule.poi.expression)) {
          console.log(rule.poi)
          textBgColor = rule.poi.value;
          textBgColorSet = true;
        }
      }

      if (rule.poi.key == "LABEL") {
        if (text == null && evaluateExpression(feature, rule.poi.expression)) {
          text = replaceTemplate(feature, rule.poi.value, false);
        }
      }
    }

    //SAVE STYLE TO CACHE
    if (style == null) {
      if (gisStyleCaches[poiUniqueId] == null) {
        gisStyleCaches[poiUniqueId] = {};
      }
      gisStyleCaches[poiUniqueId][feature.get("blobGuid")] = {
        fillColor: fillColor,
        strokeColor: strokeColor,
        strokeWidth: strokeWidth,
        text: text,
        textSize: textSize,
        textColor: textColor,
        textBgColor: textBgColor,
      };
    }

    return new Style({
      text: new Text({
        text: text,
        font: textSize + "px Calibri,sans-serif",
        fill: new Fill({
          color: textColor,
        }),
        stroke: new Stroke({
          color: textBgColor,
          width: 3,
        }),
      }),
      stroke: new Stroke({
        color: strokeColor,
        width: strokeWidth,
      }),
      fill: new Fill({
        color: fillColor,
      }),
    });
  }

  function drawPoiGeometries() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    for (let e of Object.entries(props.selectedPois)) {
      let poiUniqueId = e[0];
      let pois = e[1];
      let config = props.configs.find((config) => config.config.namespace + "/" + config.config.containerId == poiUniqueId);
      let vectorOverlayKey = "x_map";
      if(config) {
        let vectorOverlay = config.config.rows.find((row) => row.type == "vectorOverlay");
        if(vectorOverlay != null && vectorOverlay.id != null) vectorOverlayKey = vectorOverlay.id;
      }

      let gisStyle = props.selectedStyle
        ? props.gisStyles[poiUniqueId]
          ? props.gisStyles[poiUniqueId].find(
              (element) =>
                element.poi.std.guid ==
                props.selectedStyle[poiUniqueId + "//" + vectorOverlayKey]
            )
          : null
        : null;
      let vecLayer;

      for (let layer of layers) {
        if (layer.get("layerId") == poiUniqueId + "_" + vectorOverlayKey) {
          vecLayer = layer;
          vecLayerRef.current = layer;
          continue;
        }
      }

      if (vecLayer == null) {
        const vectorSource = new VectorSource();
        vecLayer = new VectorLayer({
          source: vectorSource,
          opacity: 1,
          layerId: poiUniqueId + "_" + vectorOverlayKey,
          zIndex: 998
        });
        vecLayerRef.current = vecLayer;
        if (map != undefined) {
          mapRef.current.addLayer(vecLayer);
        }
      }

      let features = [];
      if (pois != null) {
        pois.forEach((poi, idx) => {
          if (props.mapConfig[poiUniqueId] && props.mapConfig[poiUniqueId].showLayer) {
            let blobKml = "";
            if(poi.poi[vectorOverlayKey]) {
              if(poi.poi[vectorOverlayKey].base64) {
                blobKml = atob(poi.poi[vectorOverlayKey].base64);
              } else {
                let blob = props.selectedBlobs[poiUniqueId] ? props.selectedBlobs[poiUniqueId].find((blob) => blob.poiguid == poi.std.guid) : null;
                if(blob) {
                  blobKml = blob.kml;
                }
              }
            }
            let kml = new KML({
              extractStyles: gisStyle == null,
            });
            let innerFeatures = kml.readFeatures(blobKml, {
              dataProjection: "EPSG:4326",
              featureProjection: "EPSG:3857",
            });
            innerFeatures.forEach((feature) => {
              feature.setProperties(poi.poi);
              feature.setProperties({
                layerId: poiUniqueId + "_" + vectorOverlayKey,
                blobGuid: poi.std.guid,
              });
            });
            features = features.concat(innerFeatures);
          }
        });
      }

      if (vecLayer != undefined && gisStyle != null) {
        vecLayerRef.current.setStyle(evaluateStyle);
      }

      if (vecLayerRef.current) {
        vecLayerRef.current.getSource().clear();
        vecLayerRef.current.getSource().addFeatures(features);
      }
    }
  }

  

  //#region  Export 
  
  function getSelectedPois() {
    return props.selectedPois;
  }

  function getSelectedBlobs() {
    return props.selectedBlobs;
  }

  function getSelectedStyle() {
    return props.selectedStyle;
  }

  function getMapConfig() {
    return props.mapConfig;
  }

  function getExportMapPos() {
    if(mapRef.current) {
      const pos = toLonLat(mapRef.current.getView().getCenter());
      let posObj = {
        pos: pos,
        zoom: mapRef.current.getView().getZoom(),
        bounds: mapRef.current.getView().calculateExtent(mapRef.current.getSize())
      };
      return posObj;
    }
  }

  function exportMapAsImage(dim, resolution, scale, orientation) {
    const width = parseInt(((orientation == "portrait" ? dim[0] : dim[1]) * resolution) / 25.4);
    const height = parseInt(((orientation == "portrait" ? dim[1] : dim[0]) * resolution) / 25.4);

    return new Promise((resolve, reject) => {
      let desiredHeight = height;
      let desiredWidth = width;

      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = desiredWidth;
      mapCanvas.height = desiredHeight;
      const mapContext = mapCanvas.getContext('2d');
      mapContext.fillStyle = '#000000'; // Fill with white background if needed
      mapContext.fillRect(0, 0, desiredWidth, desiredHeight);

      function filter(node) {
        const exclusionClasses = ['ol-zoom'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
      };

      htmlToImage.toCanvas(mapElement.current, {filter: filter}).then((exportCanvas) => {
        const opacity = exportCanvas.style.opacity;
        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
        mapContext.drawImage(exportCanvas, 0, 0, desiredWidth, desiredHeight);
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);

        const dataUrl = mapCanvas.toDataURL();
        resolve(dataUrl);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
        resolve(null);
      });
    });
  }

  const { setExportFunction, setGetSelectedPoisFunction, setGetSelectedBlobsFunction, setGetSelectedStyleFunction, setGetMapConfigFunction, setGetExportMapPosFunction } = useMap();
  useEffect(() => {
    setExportFunction(exportMapAsImage);
    setGetSelectedPoisFunction(getSelectedPois);
    setGetSelectedBlobsFunction(getSelectedBlobs);
    setGetSelectedStyleFunction(getSelectedStyle);
    setGetMapConfigFunction(getMapConfig);
    setGetExportMapPosFunction(getExportMapPos);
  }, [props.selectedPois, props.selectedBlobs, props.selectedStyle, props.mapConfig]);

  //#endregion

  useEffect(() => {
    if(mapRef.current) {
      if(props.mapScale != "custom") {
        mapRef.current.getView().set('moveInitiatedProgrammatically', true);
        setMapScale(mapRef.current, props.mapScale);
      }

      if(props.mapScale == "fit") {
        var poiExtent;
        var geomExtent;
        if(mapRef.current) {
          let layers = mapRef.current.getAllLayers();
          layers.map((layer) => {
            if(layer.get("layerId")) {
              //POI
              if(layer.get("zIndex") == 999 && layer.getSource().getExtent() != undefined) {
                if(poiExtent == undefined) poiExtent = createEmpty();
                extend(poiExtent, layer.getSource().getExtent())
              }
              //Geoms
              if(layer.get("zIndex") == 998 && layer.getSource().getExtent() != undefined) {
                if(geomExtent == undefined) geomExtent = createEmpty();
                extend(geomExtent, layer.getSource().getExtent())
              }
            }
          });
        }
  
        let hasGeometries = geomExtent != null;
        let hasPoiIcons = poiExtent != null;
  
        if(hasPoiIcons && hasGeometries) {
          var extent = createEmpty();
          extend(extent, poiExtent);
          extend(extent, geomExtent);
  
          if(mapRef.current != null && props.selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20
            };
            if(extent != null && extent[0] != Infinity) mapRef.current.getView().fit(extent, animationOptions);
          }
        }
  
        if(hasPoiIcons && !hasGeometries) {
          if(mapRef.current != null && props.selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20
            };
            if(poiExtent != null && poiExtent[0] != Infinity) mapRef.current.getView().fit(poiExtent, animationOptions);
          }
        }
  
        if(!hasPoiIcons && hasGeometries) {
          if (mapRef.current != null && props.selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20
            };
            if(geomExtent != null && geomExtent[0] != Infinity) mapRef.current.getView().fit(geomExtent, animationOptions);
          }
        }
      }
    }
  }, [props.mapScale, currentMapPos]);

  useEffect(() => {
    if(mapRef.current) {
      mapRef.current.getInteractions().forEach(function(interaction) {
        if (interaction instanceof MouseWheelZoom) {
          interaction.setActive(props.mapScale == "custom");
        }
      }, this);
    }

    document.getElementById("basicmapexport").onwheel = function() {
      if(props.mapScale != "custom") {
        props.onDisabledZoomScroll(true);
      }
    }
  }, [props.mapScale]);


  class OEMLabel extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      const img1 = document.createElement("img");
      img1.className = "ol-oem-label";
      img1.draggable = false;
      img1.src = poweredByWiv;

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-oem-label-control"
      mainDiv.className = "ol-control ol-oem-label ol-hidden";
      mainDiv.appendChild(img1);

      super({
        element: mainDiv,
        target: options.target,
      });
    }
  }

  class NorthArrow extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      const img1 = document.createElement("img");
      img1.className = "ol-north-arrow";
      img1.draggable = false;
      img1.src = northPointer;

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-north-arrow-control-id"
      mainDiv.className = "ol-control ol-north-arrow-control ol-hidden";
      mainDiv.appendChild(img1);

      super({
        element: mainDiv,
        target: options.target,
      });
    }


    setVisible(visible) {
      let mainDiv = document.getElementById("ol-north-arrow-control-id");
      if(!mainDiv) return;
      if(visible) {
        mainDiv.classList.remove("ol-hidden");
      } else {
        mainDiv.classList.add("ol-hidden");
      }
    }
  }

  class MapInfo extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      
      const fileName = document.createElement("p");
      fileName.id = "ol-info-filename"
      fileName.className = "ol-info-filename";
      fileName.innerHTML = "<b>Name: </b>" + props.fileName;

      const createdBy = document.createElement("p");
      createdBy.id = "ol-info-created-by"
      createdBy.className = "ol-info-created-by";
      createdBy.innerHTML = "<b>Erstellt durch: </b>" + props.username;

      const mapScale = document.createElement("p");
      mapScale.id = "ol-info-map-scale"
      mapScale.className = "ol-info-map-scale";
      mapScale.innerHTML = "<b>Maßstab: </b>" + scales[props.mapScale];

      const img1 = document.createElement("img");
      img1.className = "ol-info-company-icon";
      img1.draggable = false;
      img1.src = props.companyLogo;

      const imgDiv = document.createElement("div");
      imgDiv.id = "ol-info-company-icon-div"
      imgDiv.className = "ol-info-company-icon-div";
      imgDiv.appendChild(img1);

      const leftDiv = document.createElement("div");
      leftDiv.id = "ol-info-left"
      leftDiv.className = "ol-control ol-info-left";
      leftDiv.appendChild(createdBy);
      leftDiv.appendChild(mapScale);
      leftDiv.appendChild(imgDiv);

      const rightDiv = document.createElement("div");
      rightDiv.id = "ol-info-right"
      rightDiv.className = "ol-control ol-info-right";

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-info-control"
      mainDiv.className = "ol-control ol-info-control";
      mainDiv.appendChild(leftDiv);
      mainDiv.appendChild(rightDiv);

      super({
        element: mainDiv,
        target: options.target,
      });
    }

    setCompanyIconVisible(visible) {
      let mainDiv = document.getElementById("ol-north-arrow-control");
      if(!mainDiv) return;

      if(visible) {
        mainDiv.classList.remove("ol-hidden");
      } else {
        mainDiv.classList.add("ol-hidden");
      }
    }

    setLegendVisible(visible) {
      if(visible) {
        const legendTab = document.createElement("div");
        legendTab.id = "ol-info-legend"
        legendTab.className = "ol-info-legend";

        const legendInfo = document.createElement("p");
        legendInfo.id = "ol-info-legend-info"
        legendInfo.className = "ol-info-legend-info";
        legendInfo.innerHTML = "<b>Legende: </b>";
        legendTab.appendChild(legendInfo);

        props.configs.map((config) => {
          let visisbleOnMap = props.mapConfig[config.config.namespace + "/" + config.config.containerId] ? props.mapConfig[config.config.namespace + "/" + config.config.containerId].showIcons : false;

          if(props.selectedPois[config.config.namespace + "/" + config.config.containerId] && props.selectedPois[config.config.namespace + "/" + config.config.containerId].length > 0 && visisbleOnMap) {
            const gridItemImg = document.createElement("img");
            gridItemImg.className = "ol-info-icon";
            gridItemImg.src = "data:image/jpeg;base64," + config.config["containerChooseIconBase64"]

            const gridItemTxt = document.createElement("p");
            gridItemTxt.className = "ol-info-text";
            gridItemTxt.textContent = config.config.containerTitle;

            const gridItem = document.createElement("div");
            gridItem.id = "ol-info-legend-item"
            gridItem.className = "ol-info-legend-item";
            gridItem.appendChild(gridItemImg);
            gridItem.appendChild(gridItemTxt);
            legendTab.appendChild(gridItem);

            let rightDiv = document.getElementById("ol-info-right");
            if(rightDiv) {
              rightDiv.appendChild(legendTab);
            }
          }

          //Add GisStyle Legend for Icons
          if(props.gisStyles[config.config.namespace + "/" + config.config.containerId]) {
            let selectedStyle = props.selectedStyle[config.config.namespace + "/" + config.config.containerId];
            let gisStyles = props.gisStyles[config.config.namespace + "/" + config.config.containerId];
            let selectedPoisByUniqueId = props.selectedPois[config.config.namespace + "/" + config.config.containerId];

            let gisStyle = (selectedPoisByUniqueId != null && selectedPoisByUniqueId.length > 0) ? gisStyles.find((style) => style.poi.std.guid == selectedStyle) : null;

            if(gisStyle) {
              gisStyle.childs.map((rule) => {
                if((rule.poi.key == "LINE_COLOR" || rule.poi.key == "FILL_COLOR" || rule.poi.key == "ICON_URL") && rule.poi.comment != null && rule.poi.comment != "") {

                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.poi.key == "LINE_COLOR") {
                    gridItemImg.style = "border: 4px solid " + rule.poi.value + "; height: 20px; width: 20px; border-radius: 100vh;";
                  } else if(rule.poi.key == "FILL_COLOR") {
                    gridItemImg.style = "background-color: " + rule.poi.value + "; height: 28px; width: 28px; border-radius: 100vh;";
                  } else {
                    const gridItemImgIcon = document.createElement("img");
                    gridItemImgIcon.src = rule.poi.value != null && rule.poi.value != "" && rule.poi.value.includes("://") ? rule.poi.value : "http://www.portal.wood-in-vision.com/runtime/" + rule.poi.value;
                    gridItemImgIcon.id = "ol-info-legend-item-img"
                    gridItemImgIcon.style = "height: 28px; width: 28px;";
                    gridItemImg.appendChild(gridItemImgIcon);
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.poi.comment;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }


          //Add GisStyle Legend
          if(props.gisStyles[config.config.namespace + "/" + config.config.containerId]) {
            let selectedStyle = props.selectedStyle[config.config.namespace + "/" + config.config.containerId + "//x_map"];
            let gisStyles = props.gisStyles[config.config.namespace + "/" + config.config.containerId];
            let selectedPoisByUniqueId = props.selectedPois[config.config.namespace + "/" + config.config.containerId];

            let gisStyle = (selectedPoisByUniqueId != null && selectedPoisByUniqueId.length > 0) ? gisStyles.find((style) => style.poi.std.guid == selectedStyle) : null;
            if(gisStyle) {
              gisStyle.childs.map((rule) => {
                if((rule.poi.key == "LINE_COLOR" || rule.poi.key == "FILL_COLOR") && rule.poi.comment != null && rule.poi.comment != "") {

                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.poi.key == "LINE_COLOR") {
                    gridItemImg.style = "border: 4px solid " + rule.poi.value + "; height: 20px; width: 20px; border-radius: 4px;";
                  } else {
                    gridItemImg.style = "background-color: " + rule.poi.value + "; height: 28px; width: 28px; border-radius: 4px;";
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.poi.comment;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }

          config.childs.map((childCfg) => {
            if(props.selectedPois[childCfg.config.namespace + "/" + childCfg.config.containerId] && props.selectedPois[childCfg.config.namespace + "/" + childCfg.config.containerId].length > 0 && visisbleOnMap) {
              const gridItemImg = document.createElement("img");
              gridItemImg.className = "ol-info-icon";
              gridItemImg.src = "data:image/jpeg;base64," + childCfg.config["containerChooseIconBase64"]
  
              const gridItemTxt = document.createElement("p");
              gridItemTxt.className = "ol-info-text";
              gridItemTxt.textContent = childCfg.config.containerTitle;
  
              const gridItem = document.createElement("div");
              gridItem.id = "ol-info-legend-item"
              gridItem.className = "ol-info-legend-item";
              gridItem.appendChild(gridItemImg);
              gridItem.appendChild(gridItemTxt);
              legendTab.appendChild(gridItem);
  
              let rightDiv = document.getElementById("ol-info-right");
              if(rightDiv) {
                rightDiv.appendChild(legendTab);
              }
            }

            //Add GisStyle Legend for Icons
          if(props.gisStyles[childCfg.config.namespace + "/" + childCfg.config.containerId]) {
            let selectedStyle = props.selectedStyle[childCfg.config.namespace + "/" + childCfg.config.containerId];
            let gisStyles = props.gisStyles[childCfg.config.namespace + "/" + childCfg.config.containerId];
            let selectedPoisByUniqueId = props.selectedPois[childCfg.config.namespace + "/" + childCfg.config.containerId];

            let gisStyle = (selectedPoisByUniqueId != null && selectedPoisByUniqueId.length > 0) ? gisStyles.find((style) => style.poi.std.guid == selectedStyle) : null;

            if(gisStyle) {
              gisStyle.childs.map((rule) => {
                if((rule.poi.key == "LINE_COLOR" || rule.poi.key == "FILL_COLOR" || rule.poi.key == "ICON_URL") && rule.poi.comment != null && rule.poi.comment != "") {

                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.poi.key == "LINE_COLOR") {
                    gridItemImg.style = "border: 4px solid " + rule.poi.value + "; height: 20px; width: 20px; border-radius: 100vh;";
                  } else if(rule.poi.key == "FILL_COLOR") {
                    gridItemImg.style = "background-color: " + rule.poi.value + "; height: 28px; width: 28px; border-radius: 100vh;";
                  } else {
                    const gridItemImgIcon = document.createElement("img");
                    gridItemImgIcon.src = rule.poi.value != null && rule.poi.value != "" && rule.poi.value.includes("://") ? rule.poi.value : "http://www.portal.wood-in-vision.com/runtime/" + rule.poi.value;
                    gridItemImgIcon.id = "ol-info-legend-item-img"
                    gridItemImgIcon.style = "height: 28px; width: 28px;";
                    gridItemImg.appendChild(gridItemImgIcon);
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.poi.comment;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }


          //Add GisStyle Legend
          if(props.gisStyles[childCfg.config.namespace + "/" + childCfg.config.containerId]) {
            let selectedStyle = props.selectedStyle[childCfg.config.namespace + "/" + childCfg.config.containerId + "//x_map"];
            let gisStyles = props.gisStyles[childCfg.config.namespace + "/" + childCfg.config.containerId];
            let selectedPoisByUniqueId = props.selectedPois[childCfg.config.namespace + "/" + childCfg.config.containerId];

            let gisStyle = (selectedPoisByUniqueId != null && selectedPoisByUniqueId.length > 0) ? gisStyles.find((style) => style.poi.std.guid == selectedStyle) : null;
            if(gisStyle) {
              gisStyle.childs.map((rule) => {
                if((rule.poi.key == "LINE_COLOR" || rule.poi.key == "FILL_COLOR") && rule.poi.comment != null && rule.poi.comment != "") {

                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.poi.key == "LINE_COLOR") {
                    gridItemImg.style = "border: 4px solid " + rule.poi.value + "; height: 20px; width: 20px; border-radius: 4px;";
                  } else {
                    gridItemImg.style = "background-color: " + rule.poi.value + "; height: 28px; width: 28px; border-radius: 4px;";
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.poi.comment;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }
          })

        })
      } else {
        let rightDiv = document.getElementById("ol-info-right");
        let legend = document.getElementById("ol-info-legend");
        if(rightDiv && legend) {
          rightDiv.removeChild(legend);
        }
      }
    }

    setFileNameVisible(visible) {
      let leftDiv = document.getElementById("ol-info-left");
      if(!leftDiv) return;

      if(visible) {
        const fileName = document.createElement("p");
        fileName.id = "ol-info-filename"
        fileName.className = "ol-info-filename";
        fileName.innerHTML = "<b>Name: </b>" + props.fileName;
        leftDiv.insertBefore(fileName, leftDiv.firstChild);
      } else {
        let fileName = document.getElementById("ol-info-filename");
        if(fileName) {
          leftDiv.removeChild(fileName);
        }
      }
    }

    setFileName(fileName) {
      let fileNameP = document.getElementById("ol-info-filename");
      if(!fileNameP) return;
      fileNameP.innerHTML = "<b>Name: </b>" + fileName;
    }

    setMapScale(mapScale) {
      let mapScaleP = document.getElementById("ol-info-map-scale");
      if(!mapScaleP) return;
      mapScaleP.innerHTML = "<b>Maßstab: </b>" + scales[mapScale];
    }
  }

  const oemLabel = new OEMLabel();
  oemLabelRef.current = oemLabel;

  const northArrow = new NorthArrow();
  northArrowRef.current = northArrow;

  const mapInfo = new MapInfo();
  mapInfoRef.current = mapInfo;

  const scaleLine = new ScaleLine({
    bar: false,
    steps: 5,
    text: true,
    minWidth: "200",
    maxWidth: "300"
  });
  scaleLineRef.current = scaleLine;



  



  useEffect(() => {
    if(northArrowRef.current) {
      northArrowRef.current.setVisible(props.showNorthArrow);
    }
  }, [props.showNorthArrow, mapRef.current]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setLegendVisible(props.showLegend);
    }
  }, [props.showLegend]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileNameVisible(props.showFileName);
    }
  }, [props.showFileName]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileName(props.fileName);
    }
  }, [props.fileName]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setMapScale(props.mapScale);
    }
  }, [props.mapScale]);

  //#region  Main Setup 

  //Autorefreshs
  function initMap() {
    mapElement.current.innerHTML = "";

    baseSrc.set('layerKey', "google_luftbild");
    var baseLayer = new TileLayer({
      type: "base_layer",
      source: baseSrc,
    });
    baseLayerRef.current = baseLayer;

    let initMap = new Map({
      controls: defaultControls({
        attribution: false,
        zoom: false,
      }).extend([oemLabel, northArrow, mapInfo, scaleLine]),
      target: mapElement.current,
      layers: [baseLayer],
      view: new View({
        center: fromLonLat(props.initMapPosition.pos ? props.initMapPosition.pos : [10.470215, 50.750766]),
        zoom: props.initMapPosition.zoom ? props.initMapPosition.zoom : 10,
        minZoom: 5,
        maxZoom: 22,
      }),
    });

    mapRef.current = initMap;

    initMap.on('movestart', function(e) {
      props.onIsRenderingChanged(true);
    });

    initMap.on('rendercomplete', function(e) {
      props.onIsRenderingChanged(false);
    });

    initMap.on('moveend', function(e) {
      var mapView = mapRef.current.getView(), moveInitiatedProgrammatically = mapView.get('moveInitiatedProgrammatically') || false;
      mapView.unset('moveInitiatedProgrammatically');
      if(!moveInitiatedProgrammatically) {
        const map = e.map;
        const pos = toLonLat(map.getView().getCenter());
        let posObj = {
          pos: pos,
          zoom: map.getView().getZoom(),
          bounds: map.getView().calculateExtent(map.getSize())
        };
        setCurrentMapPos(posObj);
        props.onMapPositionChanged(posObj);
      }
    });

    initMap.getInteractions().forEach(function(interaction) {
      if (interaction instanceof MouseWheelZoom) {
        interaction.setActive(props.mapScale == "custom");
      }
    }, this);

    //Filename
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileNameVisible(props.showFileName);
    }

    //Legend
    if(mapInfoRef.current) {
      mapInfoRef.current.setLegendVisible(props.showLegend);
    }

    setMap(initMap);
    props.onMapPositionChanged({
      pos: [
        props.poiPosition ? props.poiPosition.lon : 10.470215,
        props.poiPosition ? props.poiPosition.lat : 50.750766,
      ],
      zoom: initMap.getView().getZoom(),
      bounds: initMap.getView().calculateExtent(initMap.getSize())
  });
  }

  useEffect(() => {
    drawPoiGeometries();
    drawPoisPosition();
  }, [props.selectedPois, props.selectedBlobs, props.selectedStyle, props.mapConfig, mapRef.current, props.clusterIcons]);


  function handleMapLayer() {
    if(mapRef.current) {
      //baseLayer
      let selectedBaseLayer = props.layerArr ? props.layerArr.find((layer) => layer.layerKey == props.enabledBaseLayer) : null;
      if(selectedBaseLayer) {
        let source = null;
        if(selectedBaseLayer.layerKey == "osm") {
          source = new OSM({
            crossOrigin:"anonymous"
          });
        } else {
          switch(selectedBaseLayer.layerType ? selectedBaseLayer.layerType : "") {
            case "wms":
              source = new TileWMS({
                url: selectedBaseLayer.layerUrl,
                params: selectedBaseLayer.layerParams,
                crossOrigin:"anonymous"
              })
              break;
            default:
              source = new XYZ({
                url: selectedBaseLayer.layerUrl ? selectedBaseLayer.layerUrl.replaceAll("$", "") : "",
                crossOrigin:"anonymous"
              });
              break;
          }
        }

        source.set("layerKey", selectedBaseLayer.layerKey);
        let baseLayer = mapRef.current.getLayers().getArray().find((layer) => layer.get("type") == "base_layer");
        if(baseLayer) {
          let currentSource = baseLayer.getSource();
          if(currentSource && currentSource.get("layerKey") != source.get("layerKey")) {
            baseLayer.setSource(source);
          }
        }
      }


      //Overlay
      let newSelectedLayers = [];
      props.enabledLayers.map((enabledlayer) => {
        let layer = props.layerArr ? props.layerArr.find((layer) => layer.layerKey == enabledlayer) : null;
        if(layer) {
          let source = null;
          if(layer.layerKey == "osm") {
            source = new OSM({
              crossOrigin:"anonymous"
            });
          } else {
            switch(layer.layerType ? layer.layerType : "") {
              case "wms":
                source = new TileWMS({
                  url: layer.layerUrl,
                  params: layer.layerParams,
                  crossOrigin:"anonymous"
                })
                break;
              default:
                source = new XYZ({
                  url: layer.layerUrl.replaceAll("$", ""),
                  crossOrigin:"anonymous"
                });
                break;
            }
          }
          source.set("layerKey", enabledlayer);
          let newLayer = new TileLayer({
            type: "overlay_layer",
            source: source
          });
          newSelectedLayers.push(newLayer);
        }
      });

      let currentLayers = mapRef.current.getLayers();

      //Remove old layers
      currentLayers.getArray().map((currentLayer) => {
        let foundLayer = newSelectedLayers ? newSelectedLayers.find((layer) => layer.getSource().get("layerKey") == currentLayer.getSource().get("layerKey")) : null;
        if(!foundLayer && currentLayer.get("type") == "overlay_layer") {
          mapRef.current.removeLayer(currentLayer);
        }
      });

      //Add new layers
      newSelectedLayers.map((newLayer) => {
        let foundLayer = currentLayers ? currentLayers.getArray().find((layer) => layer.getSource().get("layerKey") == newLayer.getSource().get("layerKey")) : null;
        if(!foundLayer) {
          mapRef.current.addLayer(newLayer);
        }
      });
    }
  }

  //Change Layer
  useEffect(() => {
    handleMapLayer();
  }, [props.enabledBaseLayer, props.enabledLayers]);

  useEffect(() => {
    initMap();
    handleMapLayer();
  }, []);

  return (
    <>
      <div
        id="basicmapexport"
        ref={mapElement}
        className="basicmapexport"
        style={{
          width: "100%",
          height: "100%"
        }}
      >
      </div>
    </>
  );
}

export default BasicMapExport;

//#endregion
import React, { createContext, useContext, useState } from 'react';

const MapContext = createContext();

export  const MapProvider = ({ children }) => {
    const [mapData, setMapData] = useState({
        exportFunction: null
    });

    // Function that updates the map function
    const setExportFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            exportFunction: func
        }));
    };

    const setGetGisStyleFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getGisStylesFunction: func
        }));
    };

    const setGetSelectedPoisFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getSelectedPoisFunction: func
        }));
    };

    const setGetSelectedBlobsFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getSelectedBlobsFunction: func
        }));
    };


    const setGetLayerArrFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getLayerArrFunction: func
        }));
    };

    const setGetEnabledBaseLayerFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getEnabledBaseLayerFunction: func
        }));
    };

    const setGetEnabledLayersFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getEnabledLayersFunction: func
        }));
    };


    const setGetSelectedStyleFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getSelectedStyleFunction: func
        }));
    };

    const setGetMapConfigFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getMapConfigFunction: func
        }));
    };

    const setGetMapPosFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getMapPosFunction: func
        }));
    };

    const setGetExportMapPosFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getExportMapPosFunction: func
        }));
    };

    const setGetGeometryAsKmlFunction = (func) => {
        setMapData(prevState => ({
            ...prevState,
            getGeometryAsKmlFunction: func
        }));
    };

    const setChangeIgnoreZoomOnGeometry = (func) => {
        setMapData(prevState => ({
            ...prevState,
            changeIgnoreZoomOnGeometryFunction: func
        }));
    };

    return (
        <MapContext.Provider value={{ 
            mapData, 
            setExportFunction, 
            setGetGisStyleFunction, 
            setGetSelectedPoisFunction, 
            setGetSelectedBlobsFunction, 
            setGetLayerArrFunction, 
            setGetEnabledBaseLayerFunction, 
            setGetEnabledLayersFunction,
            setGetSelectedStyleFunction, 
            setGetMapConfigFunction, 
            setGetMapPosFunction, 
            setGetExportMapPosFunction,
            setGetGeometryAsKmlFunction,
            setChangeIgnoreZoomOnGeometry
        }}>
            {children}
        </MapContext.Provider>
    );
};
export default MapProvider;
export const useMap = () => useContext(MapContext);
